import '../styling/Home.css';
import React, { useState } from "react";
import {FaUpload, FaPlus, FaPen, FaArrowRight} from 'react-icons/fa';
import { Link, Navigate, useNavigate } from "react-router-dom";

import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import app from "../utils/firebase";

import customize1 from '../assets/customize-1.png';
import customize2 from '../assets/customize-2.png';
import customize3 from '../assets/customize-3.png';

const auth = getAuth(app);

const Home = () => {
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [boxClicked, setBoxClicked] = React.useState(false);
  const [url, setUrl] = React.useState("");

  const navigation = useNavigate();

  const boxSubmit = () => {

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
        setBoxClicked(true);
      } else {
        setAuthenticated(false);
        setBoxClicked(true);
      }
    });
    
  }
    return (
      <div className="App">
        {isAuthenticated && boxClicked && (
          // <Navigate to={"/dashboard"} state={{url}} />
          navigation("/dashboard", {state: {startUrl: url}})
        )}
        {!isAuthenticated && boxClicked && (
          <Navigate to="/signup" />
        )}
        <header className="App-header">
          <div className="header">
            <div className="headerText">
              <h1 className="headerTitle">Google Sheets has a new superpower.</h1>
              <p id='span-desc' className="headerDesc">Sheet2Feed is a simple way to convert the data in your Google Sheets into a fully functioning website.</p>
            </div>
            <div className="headerBox">
              <p className="headerBoxText">Connect your Google Sheet to get started</p>
              <div className="headerBoxInput">
                <input className="headerBoxTextBox" value={url} onChange={ev => setUrl(ev.target.value)} type="text" placeholder="Google Sheet URL" />
                <button className="headerBoxButton" onClick={boxSubmit} >
                  <FaArrowRight color="white" size="25"/>
                </button>
              </div>
            </div>
          </div>

          <div className="about" id="about">
            <h1>Launch Your Feed In <strong>3 Simple Steps</strong></h1>            
              <div className="row">
                <div className="column">
                  <div className="about-icon">
                    <FaUpload color="white" size="25"/>
                  </div>
                  <h1>Connect</h1>
                  <p>Connect any Google Sheet that you use to sort and manage large collections of content or data.</p>
                </div>
                <div className="column">
                  <div className="about-icon">
                    <FaPlus color="white" size="25"/>
                  </div>
                  <h1>Create</h1>
                  <p>Sheet2Feed automatically converts your sheet content into a social media-like, feed-based website.</p>
                </div>
                <div className="column">
                  <div className="about-icon">
                    <FaPen color="white" size="25"/>
                  </div>
                  <h1>Update</h1>
                  <p>The items in your feed will update automatically as you change/add content to your Google Sheet.</p>
                </div>
            </div>
            <div className="customize">
              <h1>Customizations To Make Your Feed Unique</h1>
              <div className="row">
                <div className="column">
                  <h1>Title & Alias</h1>
                </div>
                <div className="column">
                  <h1>Color Theme</h1>
                </div>
                <div className="column">
                  <h1>Structure</h1>
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <h1>Filters</h1>
                </div>
                <div className="column">
                  <h1>Password-Protection</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <h1>Ready to get started?</h1>
            <a className="footerButton" href={window.location.origin + "/signup"}>Sign Up Today</a>
            <br /><br />

            <div className="footerLinks">
              <a href="/pricing">Pricing</a>
              <a href="#about">About</a>
              <a href="mailto:bob@bob-brown.com">Contact</a>
            </div>

            <p>© 2024 Sheet2Feed. All rights reserved.</p>
          </div>
        </header>
      </div>
    );
}

export default Home;