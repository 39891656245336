import React, {useState, useEffect} from "react";
import {Popup} from "./FeedPopup";
import {formatDesc} from "../scripts/feed.js";
import "../styling/Feed.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app, {db} from "../utils/firebase";
import Papa from "papaparse"
import {Helmet} from 'react-helmet';
import { onValue, ref, set, get, query, equalTo, orderByChild, once, orderByValue, off, update } from "firebase/database";

export default function Feed() {
    const [feedName, setFeedName] = useState("");
    const [feedColor, setColor] = useState("#FFFFFF");
    const [feedTheme, setTheme] = useState("light");
    const [feedCols, setCols] = useState([]);
    const [feedItems, setFeedItems] = useState([]);
    const [originalFeedItems, setOriginalFeedItems] = useState([]); 
    const [feedURL, setFeedURL] = useState("");

    const [feedFilters, setFilters] = useState([]);
    const [allFilters, setAllFilters] = useState({});
    const [currentFilters, setCurrentFilters] = useState({});

    const [headlineCol, setHeadlineCol] = useState("");
    const [descCol, setDescCol] = useState("");
    const [footerCol, setFooterCol] = useState("");

    const [passwordEntered, setPasswordEntered] = useState(false);
    const [passwordText, setPasswordText] = useState("");
    const [passwordGuess, setPasswordGuess] = useState("");

    const [popup, openPopup] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const url = window.location.href;
                const alias = url.split("/").pop();
                const feedRef = ref(db, "feeds/" + alias + "/");
    
                const snapshot = await get(feedRef);
                const feed = snapshot.val();
                if (feed != null) {
                    setFeedName(feed.title);
                    setCols(feed.cols);
                    setHeadlineCol(feed.head);
                    setDescCol(feed.desc);
                    setFooterCol(feed.footer);
                    setColor(feed.color);
                    setTheme(feed.theme);
                    setFeedURL(feed.url);    
                    if (feed.password.enabled) {
                        setPasswordText(feed.password.text);
                    } 
    
                    Papa.parse(feed.url, {
                        download: true,
                        header: true,
                        complete: function (results) {
                          if (results && Array.isArray(results.data)) {
                            setFeedItems(results.data);
                            setOriginalFeedItems(results.data);
                      
                            let filters = [];
                            for (var i = 0; i < feed.filters.length; i++) {
                              if (feed.filters[i] == true) {
                                filters.push(feed.cols[i]);
                              }
                            }
                            setFilters(filters);
                            setCurrentFilters(
                                Object.fromEntries(filters.map((filter) => [filter, []]))
                            );
                      
                            let allFiltersData = {};
                            filters.forEach((filter) => {
                              let newFilter = [];
                              for (var j = 0; j < results.data.length; j++) {
                                if (results.data[j][filter] != null && !newFilter.includes(results.data[j][filter])) {
                                  newFilter.push(results.data[j][filter]);
                                }
                              }
                              allFiltersData[filter] = newFilter;
                            });
                            setAllFilters(allFiltersData);
                      
                            setLoading(false);
                          } else {
                            console.error("Parsed data is invalid:", results);
                          }
                        },
                        error: function (error) {
                          console.error("Error parsing data:", error);
                        },
                      });
                      
                } else {
                    return window.location.href = window.location.origin + "?error=fnf";
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, []);
    

    const openFeedItem = (item) => {
        openPopup({
            head: item[headlineCol],
            desc: item[descCol],
            footer: item[footerCol]
        });
      };

    const appyFilters = () => {
        let newFeedItems = [];
        for (var i = 0; i < originalFeedItems.length; i++) {
            let item = originalFeedItems[i];
            let add = true;
            for (var j = 0; j < feedFilters.length; j++) {
                let filter = feedFilters[j];
                if (currentFilters[filter].length > 0) {
                    if (!currentFilters[filter].includes(item[filter])) {
                        add = false;
                    }
                }
            }
            if (add) {
                newFeedItems.push(item);
            }
        }
        setFeedItems(newFeedItems);
    }

      return (
        <>  
            {feedTheme === "light" && !loading && (
                <Helmet>
                    <style>{'body { background-color: white; color: #1e1e1e; } .link { color: #1e1e1e} .nav { border-bottom: 1px solid grey; } .feed-item:hover { color: #1e1e1e } .feed-filter {border: 1px solid #1e1e1e; color: #1e1e1e}'}</style>
                </Helmet>
            )}
            {feedTheme === "dark" && !loading && (
                <Helmet>
                    <style>{'body { background-color: #1e1e1e; color: white; } .link { color: white} .nav { border-bottom: 1px solid white; } .feed-item:hover { color: #1e1e1e } .popup .popup-item { background-color: #1e1e1e; border: 1px solid white; } .feed-header { border-bottom: 1px solid white; } .feed-filter { border: 1px solid white; color: white }'}</style>
                </Helmet>
            )}
            <Helmet>
                <style>{`.feed-filter {background-color: ${feedColor};}`}</style>
            </Helmet>
            {feedColor !== null && feedName !== "" && !loading && (passwordEntered || passwordText == "") && (
                <div className="feed-header" style={{backgroundColor: feedColor}}>
                    <span className="feed-title">{feedName}</span>
                    <span className="feed-ad">Feed made with <a href={window.location.origin} target="_blank">Sheet2Feed</a></span>
                </div>
            )}
            <div className="feed-filters">
                {feedFilters !== null && feedFilters.length !== 0 && feedCols !== null && feedCols.length !== 0 && !loading && Object.keys(allFilters).length !== 0 && allFilters !== null && currentFilters != {} && (passwordEntered || passwordText == "") && (
                    feedFilters.map((filter, index) => (
                        <select className="feed-filter" defaultValue="" key={index} onChange={
                            (e) => {
                                let newFeedItems = [];
                                for (var i = 0; i < feedItems.length; i++) {
                                    if (feedItems[i][filter] === e.target.value) {
                                        newFeedItems.push(feedItems[i]);
                                    }
                                }
                                setFeedItems(newFeedItems);
                                let newCurrentFilters = currentFilters;
                                if (!newCurrentFilters[filter].includes(e.target.value)) {
                                    newCurrentFilters[filter].push(e.target.value);
                                }

                            }
                        }>
                            <option hidden value="">Filter by {filter}</option>
                            {allFilters[filter].map((item, ind) => (
                                <option key={ind} value={item}>{item}</option>
                            ))}
                        </select>
                      
                    ))
                )}
            </div>  
            <div className="current-filters">
                    {feedFilters !== null && feedFilters.length !== 0 && feedCols !== null && feedCols.length !== 0 && !loading && currentFilters != {} && (passwordEntered || passwordText == "") && (
                        feedFilters.map((filter, index) => (
                            currentFilters[filter].map((item, ind) => (
                                <span onClick={() => {
                                    let newCurrentFilters = currentFilters;
                                    newCurrentFilters[filter].splice(ind, 1);
                                    setCurrentFilters(newCurrentFilters);
                                    
                                    appyFilters();
                                }
                                } className="current-filter" key={ind}>{item}</span>
                            ))
                        ))
                    )}
            </div>
            <div className="feed">
            {feedItems !== null && feedItems.length !== 0 && headlineCol !== null && !loading && (passwordEntered || passwordText == "") && (
                feedItems.map((item, index) => (
                    <div className="feed-item" key={index} onClick={() => openFeedItem(item)}> 
                        <span className="feed-item-header">{feedItems[index][headlineCol]}</span>
                        <span className="feed-item-desc">{formatDesc(feedItems[index][descCol])}</span>
                        <div className="feed-footer">
                            <span className="feed-item-date">{feedItems[index][footerCol]}</span>
                        </div>
                    </div>
                ))
            )}
            {loading && (passwordEntered || passwordText == "") && (
                <div className="loader"></div>
            )}
            {passwordText !== "" && !passwordEntered && (
                <div className="password">
                    <h1 className="password-title">This feed is password protected.</h1>
                    <p className="password-text">Please enter the password given by the creator of the feed to continue.</p>
                    <input className="password-input" type="text" placeholder="Password" onChange={(e) => setPasswordGuess(e.target.value)}></input>
                    <button className="password-button" onClick={() => {
                        if (passwordGuess === passwordText) {
                            setPasswordEntered(true);
                        } else {
                            alert("Incorrect password");
                        }
                    }}>Submit</button>
                </div>
            )}
            </div>
            {popup ? <Popup openPopup={openPopup} item={popup} /> : null}
        </>
    )
}